import * as React from "react";
import { graphql } from "gatsby";
import TemplatePage from "../../../../components/pages/templates/TemplatePage";

const Template = ({ data }) => (
  <TemplatePage
    title="Business Website Homepage Template | Uizard"
    category="Website Templates"
    url="/templates/website-templates/business-home-page/"
    metaDescription="Embark is an easy-to-use and professional homepage template that will help you create your own business website homepage in minutes. Try it now!"
    description="
    h2:Designing landing pages might sound hard, but this business homepage design template makes it a breeze!
    <br/>
    Embark is a professional and lightweight business homepage design template, created with all the necessary <a:https://uizard.io/blog/the-ultimate-guide-to-homepage-design/>homepage design best practices</a> in mind! Created for anyone that needs an awesome website or business homepage, Embark is here to streamline your design flow. 
    <br/>
    h3:An example landing page that's simple and intuitive right from the get-go
    <br/>
    Embark is an easy-to-use, professional, and highly creative example homepage that will help you create an awesome business website in no time at all. If building landing pages isn't your forte, don't worry, embark has been intentionally designed to be simple and intuitive to adapt and customize right from the get-go. <a:https://uizard.io/prototyping/>UI Prototyping</a> has never been so easy.
    <br/>
    h3:Design landing pages the right way 
    <br/>
    Our homepage design template for websites reflects the most common content structure and information hierarchy used by the best companies out there. Compelling landing pages are always a great way to convert visitors into customers straight from your website or to simply build an email list. Sign up to Uizard and bring your design to life now.
    "
    pages={[
      "An example landing page with multiple useful sections, such as customers showcase, CTA's, reviews, and footers",
      "A product page where you can delve deeper into the problems your company is solving",
      "A pricing page that presents different tiers, as well as a FAQs (frequently asked questions) template and a case study section",
      "A team page that features your story, your mission, your team, and your job openings",
      'The best way to let potential clients introduce themselves, a "Contact Us" page equipped with a form',
      "A blog page to showcase the content that matters to your business",
    ]}
    projectCode="xVBb9mWJ0Ahjoo7eZZyr"
    img1={data.image1.childImageSharp}
    img1alt="Screenshot of Embark's business home page: overview"
    img2={data.image2.childImageSharp}
    img2alt="Screenshot of Embark's business home page: product page"
    img3={data.image3.childImageSharp}
    img3alt="Screenshot of Embark's business home page: pricing page"
    img4={data.image4.childImageSharp}
    img4alt="Screenshot of Embark's business home page: about us page"
    img5={data.image5.childImageSharp}
    img5alt="Image summary of business home page Embark template"
  />
);

export default Template;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: { eq: "templates/business-home-page/embark-cover.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: { eq: "templates/business-home-page/embark-product.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: { eq: "templates/business-home-page/embark-pricing.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: { eq: "templates/business-home-page/embark-about-us.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: { eq: "templates/business-home-page/embark-summary.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
